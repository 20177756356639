<template>
  <div>
    <div>
      <h5>1. ภาพฉายอนาคตในภาพรวมของอุปสงค์และอุปทานด้านพลังงานในกรณีต่าง ๆ</h5>
      <div class="text-danger text-center" v-if="hasError.graph1.error">
        {{ hasError.graph1.message || '' }}
      </div>
      <div class="position-relative" style="padding-left: 50px">
        <div id="graph1-container" class=""></div>

        <div class="container" v-show="ready">
          <div class="row">
            <div class="" style="width: 10%"></div>
            <div
              class="d-flex justify-content-center"
              :style="{ width: btnWidth }"
            >
              <!--<button class="btn btn-outline-red" type="button">Detail</button>-->
              <router-link
                :to="{
                  name: 'peno.scenarios.demand_case_detail',
                  params: {
                    type: 'BAU',
                  },
                }"
                class="btn btn-outline-red"
                >กรณี BAU</router-link
              >
            </div>
            <div
              class="d-flex justify-content-center"
              :style="{ width: btnWidth }"
            >
              <router-link
                :to="{
                  name: 'peno.scenarios.demand_case_detail',
                  params: {
                    type: 'EE',
                  },
                }"
                class="btn btn-outline-red"
                >กรณี EE</router-link
              >
            </div>
            <div
              class="d-flex justify-content-center"
              :style="{ width: btnWidth }"
            >
              <router-link
                :to="{
                  name: 'peno.scenarios.demand_case_detail',
                  params: {
                    type: 'EEPLUS',
                  },
                }"
                class="btn btn-outline-red"
                >กรณี EE+</router-link
              >
            </div>
            <div
              class="d-flex justify-content-center"
              :style="{ width: btnWidth }"
              v-if="containCustomScenario"
            >
              <router-link
                :to="{
                  name: 'peno.scenarios.demand_case_detail',
                  params: {
                    type: 'EE',
                  },
                }"
                class="btn btn-outline-red"
                >กรณี Custom</router-link
              >
            </div>
          </div>
        </div>
        <div
          class="position-absolute"
          v-show="ready"
          style="left: -100px; top: 50%; transform: rotate(-90deg)"
        >
          ปริมาณอุปสงค์และอุปทานด้านพลังงาน (ktoe)
        </div>
      </div>
    </div>
    <h5 class="mt-4">
      2. ภาพรวมของมูลค่าพลังงานของภาพฉายอนาคตด้านพลังงานในกรณีต่าง ๆ
    </h5>
    <div class="text-danger text-center" v-if="hasError.graph2.error">
      {{ hasError.graph2.message || '' }}
    </div>
    <div class="position-relative" style="padding-left: 50px">
      <div id="graph2-container" class="mt-2"></div>
      <div
        class="position-absolute"
        v-show="ready"
        style="left: -50px; top: 50%; transform: rotate(-90deg)"
      >
        มูลค่าพลังงาน (พันบาท)
      </div>
    </div>
    <h5 class="mt-4">
      3. ภาพรวมของการปล่อยก๊าซเรือนกระจกของภาพฉายอนาคตด้านพลังงานในกรณีต่าง ๆ
    </h5>
    <div class="text-danger text-center" v-if="hasError.graph3.error">
      {{ hasError.graph3.message || '' }}
    </div>
    <div class="position-relative" style="padding-left: 50px">
      <div id="graph3-container" class="mt-2"></div>
      <div
        class="position-absolute"
        v-show="ready"
        style="left: -100px; top: 50%; transform: rotate(-90deg)"
      >
        ปริมาณการปล่อยก๊าซเรือนกระจก (tCO2e)
      </div>
    </div>
    <div v-show="noData">
      <p>ยังไม่มีข้อมูล</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'scenarios.scenrios_overview',
  data() {
    return {
      ready: false,
      noData: false,
      containCustomScenario: false,
      hasError: {
        graph1: {
          error: false,
          message: '',
        },
        graph2: {
          error: false,
          message: '',
        },
        graph3: {
          error: false,
          message: '',
        },
      },
    };
  },
  computed: {
    ...mapState('peno', ['currentReport']),
    ...mapGetters('peno', ['currentProvince']),
    btnWidth() {
      if (this.containCustomScenario) {
        return '22%';
      }
      return '30%';
    },
  },
  mounted() {
    this.fetchOverviewGraph();
  },
  methods: {
    ...mapActions('mondas', [
      'fetchScenarioOverview1',
      'fetchScenarioOverview2',
      'fetchScenarioOverview3',
    ]),
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    },
    checkContainCustomizedScenario(svgText) {
      // console.log(svgText);
      const demandCount = (svgText.match(/>Demand</g) || []).length;
      const supplyCount = (svgText.match(/>Supply</g) || []).length;
      if (demandCount >= 4 && supplyCount >= 4) {
        this.containCustomScenario = true;
      } else {
        this.containCustomScenario = false;
      }
    },
    fetchOverviewGraph() {
      this.fetchScenarioOverview1(this.currentProvince).then((graph1) => {
        console.log(graph1);
        if (graph1) {
          this.checkError(graph1, this.hasError.graph1);
          let graph1SvgContainer = document.getElementById('graph1-container');
          graph1 = this.replaceAll(graph1, 'font-size="10"', 'font-size="24"');
          graph1 = this.replaceAll(graph1, 'font-size="9"', 'font-size="22"');
          graph1SvgContainer.innerHTML = graph1;
          this.ready = true;
          if (graph1SvgContainer.childNodes[0].childNodes.length == 0) {
            this.ready = false;
            this.noData = true;
          }
          this.checkContainCustomizedScenario(graph1);
        }
      });

      this.fetchScenarioOverview2(this.currentProvince).then((graph2) => {
        if (graph2) {
          this.checkError(graph2, this.hasError.graph2);
          let graph2SvgContainer = document.getElementById('graph2-container');
          graph2 = this.replaceAll(graph2, 'font-size="10"', 'font-size="24"');
          graph2 = this.replaceAll(graph2, 'font-size="9"', 'font-size="22"');
          graph2SvgContainer.innerHTML = graph2;
        }
      });

      this.fetchScenarioOverview3(this.currentProvince).then((graph3) => {
        if (graph3) {
          this.checkError(graph3, this.hasError.graph3);
          let graph3SvgContainer = document.getElementById('graph3-container');
          graph3 = this.replaceAll(graph3, 'font-size="10"', 'font-size="24"');
          graph3 = this.replaceAll(graph3, 'font-size="9"', 'font-size="22"');
          graph3SvgContainer.innerHTML = graph3;
        }
      });
    },
    checkError(graph, errorObject) {
      if (graph.status == 'error') {
        errorObject.error = true;
        if (graph.error && typeof graph.error === 'string') {
          errorObject.message = graph.error;
        } else {
          errorObject.message = 'Error has occured';
        }
      }
    },
  },
};
</script>

<style scoped></style>
